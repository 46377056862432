define("miard/helpers/phone-helper", ["exports", "ember"], function (exports, _ember) {
	exports.phoneHelper = phoneHelper;

	/*
  * 
  *   if (strlen($phone) == 11) {
       if (substr($phone, 1, 4) == '3812') {
         $phone = '+7 (3812) '.implode('-', str_split(substr($phone, 5), 2));
       }
     }
     return $phone;
  * 
  */

	function phoneHelper(phone) {
		phone = phone + "";
		if (phone.length === 11) {
			if (phone.indexOf("3812") === 1) {
				var cityPhone = phone.substring(5);
				cityPhone = implode('-', str_split(cityPhone, 2));
				return '+7 (3812) ' + cityPhone;
			}
		}
		return phone;
	}

	function str_split(f_string, f_split_length, f_backwards) {
		// Convert a string to an array
		//
		// +	 original by: Martijn Wieringa

		if (f_backwards === undefined) {
			f_backwards = false;
		}

		if (f_split_length > 0) {
			var result = new Array();

			if (f_backwards) {
				var r = f_string.length % f_split_length;

				if (r > 0) {
					result[result.length] = f_string.substring(0, r);
					f_string = f_string.substring(r);
				}
			}

			while (f_string.length > f_split_length) {
				result[result.length] = f_string.substring(0, f_split_length);
				f_string = f_string.substring(f_split_length);
			}

			result[result.length] = f_string;
			return result;
		}

		return false;
	}

	function implode(glue, pieces) {
		// Join array elements with a string
		//
		// +   original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
		// +   improved by: _argos

		return pieces instanceof Array ? pieces.join(glue) : pieces;
	}

	exports["default"] = _ember["default"].Helper.helper(phoneHelper);
});
/*
 * формируем id для округов города так, как на miard.ru
 */