define('miard/helpers/price-helper', ['exports', 'ember'], function (exports, _ember) {
  exports.priceHelper = priceHelper;

  function _toArray(arr) { return Array.isArray(arr) ? arr : Array.from(arr); }

  //https://stackoverflow.com/questions/149055/how-can-i-format-numbers-as-dollars-currency-string-in-javascript
  /*
  format(12345678.9, 2, 3, '.', ',');  // "12.345.678,90"
  format(123456.789, 4, 4, ' ', ':');  // "12 3456:7890"
  format(12345678.9, 0, 3, '-');       // "12-345-679"
  {{price-helper item.price n=0 x=3 s=' ' c=' '}}
   */

  function priceHelper(_ref, namedArgs) {
    var _ref2 = _toArray(_ref);

    var sum = _ref2[0];

    var rest = _ref2.slice(1);

    if (sum === null) {
      return false;
    }

    var n = namedArgs.n === undefined ? '2' : namedArgs.n;
    var x = namedArgs.x === undefined ? '3' : namedArgs.x;
    var s = namedArgs.s === undefined ? '.' : namedArgs.s;
    var c = namedArgs.c === undefined ? ',' : namedArgs.c;

    sum = sum.replace(/\s/g, "");

    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = parseFloat(sum).toFixed(Math.max(0, ~ ~n));

    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
  }

  exports['default'] = _ember['default'].Helper.helper(priceHelper);
});
/*
 * формируем id для округов города так, как на miard.ru
 */