define('miard/serializers/application', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  exports['default'] = _emberData['default'].RESTSerializer.extend({
    extractMeta: function extractMeta(store, typeClass, payload) {
      /*
       * приходит из бэкэнда пустая hrForm
       * удаляем ее
       */
      if (payload && payload.hasOwnProperty('hrForm')) {
        delete payload.hrForm;
      }
    }
  });
});
//  extractAttributes(modelClass, resourceHash){
//    console.log(modelClass);
//    console.log(resourceHash);
//  }