define('miard/components/favorite-item', ['exports', 'ember', 'ember-local-storage'], function (exports, _ember, _emberLocalStorage) {
  exports['default'] = _ember['default'].Component.extend({
    favoritesService: _ember['default'].inject.service('favorites'),
    favorites: (0, _emberLocalStorage.storageFor)('favorites'),
    id: null,
    count: false,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var item = this.get('item');
      this.set('id', item.id);

      var favoritesService = this.get('favorites');
      var count = favoritesService.content.length;
      favoritesService.set('count', count);
    },
    isFavorites: _ember['default'].computed('id', function () {
      return this.get('favorites').includes(this.get('id'));
    }),

    actions: {
      favorite: function favorite(id) {
        var fav = this.get('favorites');

        var tempId = this.id;
        if (fav.includes(id)) {
          fav.removeObject(id);
        } else {
          fav.addObject(id);
          // console.log(Ember);
        }
        var count = fav.content.length;

        this.set('count', count);
        this.get('favoritesService').set('count', count);

        this.set('id', null);
        this.set('id', tempId);
      }
    }
  });
});
// app/components/like-item.js