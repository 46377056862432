define('miard/components/go-full-site', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['go_full_site'],
    cookies: _ember['default'].inject.service('cookies'),
    actions: {
      goFullSite: function goFullSite() {
        var cookieService = this.get('cookies');
        console.log(cookieService);
        cookieService.write('mredir', 0, {
          path: '/',
          expires: 0,
          domain: 'miard.ru'
        });
        window.location.replace("http://miard.ru");
      }
    }
  });
});