define('miard/serializers/property', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  exports['default'] = _emberData['default'].RESTSerializer.extend({
    meta: _ember['default'].inject.service('meta'),
    extractMeta: function extractMeta(store, typeClass, payload) {
      if (payload && payload.hasOwnProperty('meta')) {
        var metaTemp = payload.meta;
        delete payload.meta;
        this.get('meta').set('totalCount', metaTemp.totalCount);
        return metaTemp;
      }
      /*
       * приходит из бэкэнда пустая hrForm
       * удаляем ее
       */
      if (payload && payload.hasOwnProperty('hrForm')) {
        delete payload.hrForm;
      }
    },
    normalize: function normalize(model, hash, prop) {
      //    console.log(model);
      //    console.log(hash);
      //    console.log(prop);
      //hash.meta = 1;
      //  hash.meta = this.extractMeta();
      //   console.log(hash.meta);   
      //console.log(this.meta);
      return this._super.apply(this, arguments);
    }
  });
});