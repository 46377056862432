define('miard/helpers/phone-link-helper', ['exports', 'ember'], function (exports, _ember) {
  exports.phoneLinkHelper = phoneLinkHelper;

  function phoneLinkHelper(item) {
    var data = null;

    if (item === null) {
      return '#';
    }

    if (typeof item[0] === "string") {
      var phone = item[0].replace(/[^0-9|^\+]/g, '');
      if (phone[0] !== '+') {
        return '+' + phone;
      } else {
        return phone;
      }
    } else {
      data = item[0].get('data');
    }

    if (data.agentPhone) {
      return data.agentPhone.replace(/[^0-9|^\+]/g, '');
    } else if (data.affiliatePhone) {
      return data.affiliatePhone.replace(/[^0-9|^\+]/g, '');
    }
    return false;
  }

  exports['default'] = _ember['default'].Helper.helper(phoneLinkHelper);
});
/*
 * формируем id для округов города так, как на miard.ru
 */