define('miard/helpers/eq', ['exports', 'ember-truth-helpers/helpers/equal'], function (exports, _emberTruthHelpersHelpersEqual) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberTruthHelpersHelpersEqual['default'];
    }
  });
  Object.defineProperty(exports, 'equal', {
    enumerable: true,
    get: function get() {
      return _emberTruthHelpersHelpersEqual.equal;
    }
  });
});