define('miard/app', ['exports', 'ember', 'miard/resolver', 'ember-load-initializers', 'miard/config/environment'], function (exports, _ember, _miardResolver, _emberLoadInitializers, _miardConfigEnvironment) {

  var App = undefined;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = _ember['default'].Application.extend({
    //  // Basic logging, e.g. "Transitioned into 'post'"
    //  LOG_TRANSITIONS: true,
    //
    //  // Extremely detailed logging, highlighting every internal
    //  // step made while transitioning into a route, including
    //  // `beforeModel`, `model`, and `afterModel` hooks, and
    //  // information about redirects and aborted transitions
    //  LOG_TRANSITIONS_INTERNAL: true,
    modulePrefix: _miardConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _miardConfigEnvironment['default'].podModulePrefix,
    Resolver: _miardResolver['default']
  });

  (0, _emberLoadInitializers['default'])(App, _miardConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});