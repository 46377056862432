define('miard/router', ['exports', 'ember', 'miard/config/environment'], function (exports, _ember, _miardConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _miardConfigEnvironment['default'].locationType,
    rootURL: _miardConfigEnvironment['default'].rootURL
  });

  Router.map(function () {
    this.route('about');
    this.route('contact');
    this.route('property/flat');
    this.route('property/elite');
    this.route('property/garage');
    this.route('property/dacha');
    this.route('property/new');
    this.route('property/house');
    this.route('property/house-rent');
    this.route('property/rent');
    this.route('property/cottage');
    this.route('property/land');
    this.route('property/commerce');
    this.route('property/commerce-rent');
    this.route('property/favorites');

    this.route('property', function () {
      //    this.route('show', function() {
      //      this.route('property_id', { path: ':property_id' });
      //    });   
      // this.route('show', { path: '/show/:property_id' }); 

      this.route('show', { path: '/view/id/:property_id' });
    });
  });

  exports['default'] = Router;
});