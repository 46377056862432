define('miard/helpers/lte', ['exports', 'ember-truth-helpers/helpers/lte'], function (exports, _emberTruthHelpersHelpersLte) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberTruthHelpersHelpersLte['default'];
    }
  });
  Object.defineProperty(exports, 'lte', {
    enumerable: true,
    get: function get() {
      return _emberTruthHelpersHelpersLte.lte;
    }
  });
});