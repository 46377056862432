define("miard/controllers/property/show", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Controller.extend({
    currentSlide: 1,
    slideCount: null,
    onlyMap: false,
    onlyImages: false,
    loading: false,
    init: function init() {
      this._super();
      _ember["default"].run.schedule("afterRender", this, function () {
        this.send("setLoading");
      });
    },
    actions: {
      setLoading: function setLoading() {
        this.set('loading', false);
      },
      slickInit: function slickInit() {
        var model = this.get('model');
        var slideCount = model.get('images.length');
        if (slideCount) {
          this.set('currentSlide', 1);
        } else {
          this.set('currentSlide', 0);
        }
        this.set('slideCount', slideCount);
      },
      slickAfterChange: function slickAfterChange(slick) {
        this.set('currentSlide', slick.currentSlide + 1);
        this.set('slideCount', slick.slideCount);
      },
      mapToggle: function mapToggle() {
        this.toggleProperty('onlyMap');
      },
      imageToggle: function imageToggle() {
        this.toggleProperty('onlyImages');
        if (this.onlyImages) {
          _ember["default"].$('.property-images .slick-slide>div, .property-images .slick-list').height(500);
          _ember["default"].$('.property-images').addClass('contain');
        } else {
          _ember["default"].$('.property-images .slick-slide>div, .property-images .slick-list').height(170);
          _ember["default"].$('.property-images').removeClass('contain');
        }
      }
    }
  });
});