define('miard/controllers/property/favorites', ['exports', 'ember', 'ember-cli-infinite-scroll/mixins/infinite-scroll', 'miard/controllers/property/base', 'ember-local-storage'], function (exports, _ember, _emberCliInfiniteScrollMixinsInfiniteScroll, _miardControllersPropertyBase, _emberLocalStorage) {
  exports['default'] = _miardControllersPropertyBase['default'].extend(_emberCliInfiniteScrollMixinsInfiniteScroll['default'], {
    type: 'all',
    favorites: (0, _emberLocalStorage.storageFor)('favorites'),
    favoritesCount: 0,
    favoritesId: _ember['default'].computed('favorites', function () {
      var favContent = this.get('favorites').content;
      this.set('favoritesCount', favContent.length);
      return favContent;
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var favContent = this.get('favorites').content;
      this.set('favoritesCount', favContent.length);
    },
    infiniteQueryParams: ['type', 'favoritesId']
  });
});