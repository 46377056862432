define('miard/helpers/district-id', ['exports', 'ember'], function (exports, _ember) {
  exports.districtId = districtId;

  function districtId(id) {
    if (id === 8429) {
      return '1_8429';
    }
    return '0_' + id;
  }

  exports['default'] = _ember['default'].Helper.helper(districtId);
});
/*
 * формируем id для округов города так, как на miard.ru
 */