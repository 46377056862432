define('miard/routes/property/favorites', ['exports', 'ember', 'ember-cli-infinite-scroll/mixins/infinite-scroll-route', 'miard/routes/property/base', 'ember-local-storage'], function (exports, _ember, _emberCliInfiniteScrollMixinsInfiniteScrollRoute, _miardRoutesPropertyBase, _emberLocalStorage) {
  exports['default'] = _miardRoutesPropertyBase['default'].extend(_emberCliInfiniteScrollMixinsInfiniteScrollRoute['default'], {
    favoritesId: null,
    favorites: (0, _emberLocalStorage.storageFor)('favorites'),
    meta: _ember['default'].inject.service('meta'),
    currentCount: 0,
    empty: false,
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      var favContent = this.get('favorites').content;
      if (favContent.length) {
        this.set('favoritesId', favContent);
        this.get('meta').set('totalCount', favContent.length);
      }
    },
    model: function model() {
      return _ember['default'].RSVP.hash({
        properties: this.infiniteQuery('property', { type: 'all', limit: 9999, favoritesId: this.favoritesId })
      });
    },
    setupController: function setupController(controller, models) {
      controller.set('model', models.properties);
    },
    afterInfiniteQuery: function afterInfiniteQuery(newRecords) {
      var currentCount = this.get('currentCount') + newRecords.get('length');
      if (!currentCount) {
        this.set('empty', true);
        this.get('meta').set('totalCount', 0);
      }
      return this._super.apply(this, arguments);
    }
  });
});

//import RouteMixin from 'ember-cli-pagination/remote/route-mixin';