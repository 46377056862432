define('miard/adapters/application', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].RESTAdapter.extend({
    namespace: 'api',
    host: 'http://miard.ru',
    urlForFindRecord: function urlForFindRecord(id, modelName) {
      if (modelName === 'property') {
        return this.host + '/' + this.namespace + '/view/' + id;
      } else {
        return this._super.apply(this, arguments);
      }
    }
  });
});
//    urlForQuery(query, modelName) {   
//    if (modelName=='property' && 1==2){     
//      return this.host+'/'+this.namespace+'/view/'+query;
//    } else {
//      return this._super(...arguments);
//    }
//  }