define('miard/routes/property/show', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    //  queryParams: {
    //    category: {
    //      refreshModel: true
    //    }
    //  },
    beforeModel: function beforeModel(transition) {
      this.controllerFor('property.show').set('loading', true);
    },
    model: function model(params) {
      //alert(params.property_id);
      var store = this.get('store');
      var p = store.findRecord('property', params.property_id, { reload: true });
      //console.log(p);
      //p.reload(); // Force a reload
      return p;
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('model', model);
    },
    actions: {
      //      didTransition() {
      //    alert('22');
      //      //this.controller.get('errors.base').clear();
      //      return true; // Bubble the didTransition event
      //    },
      didTransition: function didTransition() {
        this.controllerFor('property.show').set('loading', false);
        window.scrollTo(0, 0);
        return true;
      },
      willTransition: function willTransition(transition) {

        return true; // Bubble the didTransition event
      },
      reloadModel: function reloadModel() {
        //alert('11');
        this.refresh();
      },
      back: function back(type) {
        type = type.replace('_', '-');
        this.transitionTo('property/' + type);
      }
    }
  });
});
//import BaseRoute from './base';