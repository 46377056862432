define('miard/components/location-map', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    maps: _ember['default'].inject.service(),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var lat = this.get('lat');
      var lng = this.get('lng');

      var mapElement = this.get('maps').getMapElement(lat, lng);
      this.$('#map').append(mapElement);
    }
  });
});