define('miard/utils/yandex-maps', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Object.extend({
    createMap: function createMap(element, lat, lng) {
      ymaps.ready(function () {
        var map = new ymaps.Map("map", {
          center: [lat, lng],
          zoom: 16
        });
        var placemark = new ymaps.Placemark([lat, lng]);
        map.geoObjects.add(placemark);
        //let windowHeight = window.innerHeight;
        _ember['default'].$('#map').height(window.innerHeight - _ember['default'].$('nav').height() - _ember['default'].$('.row.grey').height() - _ember['default'].$('footer').height() - 34);
        map.container.fitToViewport();
        return map;
      });
    }
  });
});