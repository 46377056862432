define('miard/helpers/area-total-helper', ['exports', 'ember'], function (exports, _ember) {
  exports.areaTotalHelper = areaTotalHelper;

  function areaTotalHelper(num) {
    if (!isNumeric(num)) {
      throw new TypeError('expected a number');
    }
    return Math.round(num);
  }

  function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  exports['default'] = _ember['default'].Helper.helper(areaTotalHelper);
});
/*
 * формируем id для округов города так, как на miard.ru
 */