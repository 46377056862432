define('miard/routes/property/flat', ['exports', 'ember', 'ember-cli-infinite-scroll/mixins/infinite-scroll-route', 'miard/routes/property/base'], function (exports, _ember, _emberCliInfiniteScrollMixinsInfiniteScrollRoute, _miardRoutesPropertyBase) {
    exports['default'] = _miardRoutesPropertyBase['default'].extend(_emberCliInfiniteScrollMixinsInfiniteScrollRoute['default'], {
        model: function model() {

            return _ember['default'].RSVP.hash({
                properties: this.infiniteQuery('property', { type: 'flat' }),
                districts: this.store.findAll('district'),
                flatplans: this.store.findAll('flatplan')
            });
        },
        setupController: function setupController(controller, models) {
            var bannerImage = '/img/ipoteka.jpg';

            var banner = this.get('store').createRecord('property', {
                isBunner: true,
                bannerImage: bannerImage
            });

            models.properties.pushObject(banner);
            controller.set('model', models.properties);
            controller.set('districts', models.districts);
            controller.set('flatplans', models.flatplans);
        }
    });
});
//import Ember from 'ember';
//import RouteMixin from 'ember-cli-pagination/remote/route-mixin';
//
//
//export default Ember.Route.extend(RouteMixin, {   
//    model: function(params) {           
//    return this.findPaged('property',params);
//  }, 
//  actions: {
//    search: function(data) {       
//        return false;
//    }
//  }
//});

//import RouteMixin from 'ember-cli-pagination/remote/route-mixin';