define('miard/routes/property/rent', ['exports', 'ember', 'ember-cli-infinite-scroll/mixins/infinite-scroll-route', 'miard/routes/property/base'], function (exports, _ember, _emberCliInfiniteScrollMixinsInfiniteScrollRoute, _miardRoutesPropertyBase) {
    exports['default'] = _miardRoutesPropertyBase['default'].extend(_emberCliInfiniteScrollMixinsInfiniteScrollRoute['default'], {
        model: function model() {

            return _ember['default'].RSVP.hash({
                properties: this.infiniteQuery('property', { type: 'rent' }),
                districts: this.store.findAll('district'),
                flatplans: this.store.findAll('flatplan')
            });
        },
        setupController: function setupController(controller, models) {
            controller.set('model', models.properties);
            controller.set('districts', models.districts);
            controller.set('flatplans', models.flatplans);
        }
    });
});

//import RouteMixin from 'ember-cli-pagination/remote/route-mixin';