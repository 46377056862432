define('miard/controllers/property/base', ['exports', 'ember', 'ember-cli-infinite-scroll/mixins/infinite-scroll'], function (exports, _ember, _emberCliInfiniteScrollMixinsInfiniteScroll) {
  exports['default'] = _ember['default'].Controller.extend(_emberCliInfiniteScrollMixinsInfiniteScroll['default'], {
    meta: _ember['default'].inject.service('meta'),
    start: 10,
    queryParams: ["page", "perPage", "type"],
    isOpenSearchForm: false,
    isOpenSortForm: false,
    infiniteModelName: 'property',
    infiniteQueryParams: ['type'],
    limit: 10,
    //bannerNumber:1,
    districts: null,
    streets: null,
    showStreets: false,
    didRender: function didRender() {
      //    this._super(...arguments);
      //    console.log(this.$('html').text());
      //    this.$('.menu-icon').sideNav();   
    },
    afterInfiniteQuery: function afterInfiniteQuery(newRecords) {
      //    this.set('infiniteScrollAvailable', false);   
      //    if (!newRecords.get('length')) {
      //      this.set('infiniteScrollAvailable', false);     
      //    }

      var currentCount = this.get('start');
      var bannerImage = undefined;
      /*
       * показываем баннер каждые 10 объектов, у нас всего 2 баннера, значит делим на 20
       * и проверяем очередность показа баннера
       */

      if (currentCount % 30 == 0) {
        bannerImage = '/img/fast-buy.jpg';
      } else if (currentCount % 20 == 0) {
        bannerImage = '/img/spb.jpg';
      } else {
        bannerImage = '/img/ipoteka.jpg';
      }

      //alert(currentCount % 30 +' '+currentCount+' '+bannerImage);

      var banner = this.get('store').createRecord('property', {
        isBunner: true,
        bannerImage: bannerImage
      });

      newRecords.pushObject(banner);

      if (newRecords.length < 10) {
        this.set('infiniteScrollAvailable', false);
      }
      return this._super(newRecords);
    },
    actions: {
      streetClicked: function streetClicked(streetId, streetName) {
        this.street = streetId;
        this.set('showStreets', false);
        $('#street').val(streetName);
      },
      getStreet: function getStreet(street) {
        var _this = this;
        if (street.length) {
          this.store.query('street', {
            pattern: street,
            parentId: '3311'
          }).then(function (streets) {
            _this.set('streets', streets);
            _this.set('showStreets', true);
          });
        } else {
          _this.set('street', '');
        }

        //      $.getJSON("http://miard.ru/api/ajaxstreet?pattern=" + street + "&parentId=3311").then(function(response){
        //        var $container = $("#address-street-list");
        //        $container.empty().show();
        //        $.each(response, function () {
        //          if (this.id){
        //            var $link = $("<a>");
        //            $link.text(this.type + '. ' + this.name);
        //            $link.attr('data-id', this.id);
        //            $link.attr('data-name', this.name);
        //            $link.attr('data-type', this.type);
        //            $link.attr('href', '#');
        //            $link.appendTo($container);
        //          }
        //        });
        //        $("a:first", $container).addClass("active");
        //      });
      },
      setDistrict: function setDistrict(select) {
        var result = [];
        var options = select && select.options;
        var opt;

        for (var i = 0, iLen = options.length; i < iLen; i++) {
          opt = options[i];
          if (opt.selected) {
            result.push(opt.value || opt.text);
          }
        }
        this.region_id = result;
      },
      setFlatplan: function setFlatplan(select) {
        var result = [];
        var options = select && select.options;
        var opt;

        for (var i = 0, iLen = options.length; i < iLen; i++) {
          opt = options[i];
          if (opt.selected) {
            result.push(opt.value || opt.text);
          }
        }
        this.flatplan = result;
      },
      setOrder: function setOrder(target) {
        this.set('sort', target);

        if (target === '0') {
          this.set('orderBy', 'added');
          this.set('sortBy', 'desc');
        } else if (target === '1') {
          //недорогие
          this.set('orderBy', 'price');
          this.set('sortBy', 'asc');
        } else if (target === '2') {
          //дорогие
          this.set('orderBy', 'price');
          this.set('sortBy', 'desc');
        } else if (target === '3') {
          //новые
          this.set('orderBy', 'added');
          this.set('sortBy', 'desc');
        } else if (target === '4') {
          //старые
          this.set('orderBy', 'added');
          this.set('sortBy', 'asc');
        }

        this.customQuery();
        this.set('isOpenSortForm', false);
      },
      chooseDestination: function chooseDestination(city) {
        this.set('destination', city);
      },
      toggleSearchForm: function toggleSearchForm() {
        this.toggleProperty('isOpenSearchForm');
        this.set('isOpenSortForm', false);
        Materialize.updateTextFields();
        //$('select').material_select();
      },
      toggleSortForm: function toggleSortForm() {
        this.toggleProperty('isOpenSortForm');
        this.set('isOpenSearchForm', false);
        Materialize.updateTextFields();
      },
      search: function search() {
        this.customQuery();
      }
    },
    customQuery: function customQuery() {
      var flatRoomsArr = [];
      if (this.room1) {
        flatRoomsArr.push(1);
      }
      if (this.room2) {
        flatRoomsArr.push(2);
      }
      if (this.room3) {
        flatRoomsArr.push(3);
      }
      if (this.room4) {
        flatRoomsArr.push(4);
      }
      if (this.room5) {
        flatRoomsArr.push(5);
      }

      var _price_min = this.price_min;
      var _price_max = this.price_max;
      var _square_from = this.square_from;
      var _square_to = this.square_to;
      var _region_id = this.region_id;
      var _street = this.street;
      var _flatPlan = this.flatplan;

      var _orderBy = this.orderBy;
      var _sortBy = this.sortBy;

      this.resetInfinite();
      this.infiniteQuery('property', {
        type: this.type,
        flatRooms: flatRoomsArr,
        price_min: _price_min,
        price_max: _price_max,
        square_from: _square_from,
        square_to: _square_to,
        region_id: _region_id,
        street: _street,
        flatPlan: _flatPlan,
        orderBy: _orderBy,
        orderDesc: _sortBy
      });
    },
    room1: 0,
    room2: 0,
    room3: 0,
    room4: 0,
    room5: 0,
    price_min: '',
    price_max: '',
    square_from: '',
    square_to: '',
    region_id: null,
    flatplan: null,
    street: '',
    sort: '0',
    orderBy: '',
    sortBy: ''
  });
});