define('miard/services/maps', ['exports', 'ember', 'miard/utils/yandex-maps'], function (exports, _ember, _miardUtilsYandexMaps) {
  exports['default'] = _ember['default'].Service.extend({

    init: function init() {
      if (!this.get('cachedMaps')) {
        this.set('cachedMaps', _ember['default'].Object.create());
      }
      if (!this.get('mapUtil')) {
        this.set('mapUtil', _miardUtilsYandexMaps['default'].create());
      }
    },

    getMapElement: function getMapElement(lat, lng) {
      var camelizedLocation = this.camilaze(lat) + '_' + this.camilaze(lng);
      var element = this.get('cachedMaps.${camelizedLocation}');
      if (!element) {
        element = this.createMapElement();
        this.get('mapUtil').createMap(element, lat, lng);
        this.set('cachedMaps.${camelizedLocation}', element);
      }
      return element;
    },

    createMapElement: function createMapElement() {
      var element = document.createElement('div');
      element.className = 'map';
      return element;
    },

    /**
     * Camelize a string, cutting the string by separator character.
     * @param string Text to camelize
     * @param string Word separator (underscore by default)
     * @return string Camelized text
     */
    camilaze: function camilaze(text, separator) {

      // Assume separator is _ if no one has been provided.
      if (typeof separator === "undefined") {
        separator = "_";
      }

      text = text.toString();

      // Cut the string into words
      var words = text.split(separator);

      // Concatenate all capitalized words to get camelized string
      var result = "";
      for (var i = 0; i < words.length; i++) {
        var word = words[i];
        var capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1);
        result += capitalizedWord;
      }

      return result;
    }

  });
});