define('miard/components/agent-call', ['exports', 'ember', 'ember-local-storage'], function (exports, _ember, _emberLocalStorage) {
  exports['default'] = _ember['default'].Component.extend({
    actions: {
      call: function call(item) {
        var data = item.get('data');

        if (data.agentPhone) {
          document.location.href = "tel:" + data.agentPhone.replace(/[^0-9|^\+]/g, '');
        } else if (data.affiliatePhone) {
          document.location.href = "tel:" + data.affiliatePhone.replace(/[^0-9|^\+]/g, '');
        }
        return false;
      }
    }
  });
});