define('miard/models/property', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    price: _emberData['default'].attr(),
    //desc: DS.attr(),
    thumb_image: _emberData['default'].attr(),
    address: _emberData['default'].attr(),
    district: _emberData['default'].attr(),
    areaTotal: _emberData['default'].attr(),
    areaGround: _emberData['default'].attr(),
    square: _emberData['default'].attr(),
    floor: _emberData['default'].attr(),
    floorTotal: _emberData['default'].attr(),
    roomsTotal: _emberData['default'].attr(),
    userId: _emberData['default'].attr(),
    agentFullName: _emberData['default'].attr(),
    pricePerMeter: _emberData['default'].attr(),
    agentPhone: _emberData['default'].attr(),
    affiliatePhone: _emberData['default'].attr(),
    mortagePercent: _emberData['default'].attr(),
    type: _emberData['default'].attr(),
    typeName: _emberData['default'].attr(),
    mortagePrice: _emberData['default'].attr(),
    characteristics: _emberData['default'].attr(),
    additional: _emberData['default'].attr(),
    description: _emberData['default'].attr(),
    landMark: _emberData['default'].attr(),
    similars: _emberData['default'].attr(),
    images: _emberData['default'].hasMany('image'),
    lng: _emberData['default'].attr(),
    lat: _emberData['default'].attr(),
    isBanner: false,
    bannerImage: null

    //    didLoad(e) {
    //    alert('11');
    //    console.log(e);
    //  },
    //  ready(e) {
    //    alert('11');
    //    console.log(e);
    //  },

  });
});