define('miard/components/header-area', ['exports', 'ember', 'ember-local-storage'], function (exports, _ember, _emberLocalStorage) {
  exports['default'] = _ember['default'].Component.extend({
    favoritesService: _ember['default'].inject.service('favorites'),
    count: null,
    didInsertElement: function didInsertElement() {
      _ember['default'].$(".menu-icon").sideNav({ closeOnClick: true });
    }
  });
});
//  didReceiveAttrs() {
//    this._super(...arguments);
//    let count = this.get('favorites').content.length;  
//    this.set('count', count);
//  }